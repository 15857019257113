<template>
  <div class="container-small">
    <b-card class="card" header="Zabudnuté heslo">
      <b-form @submit.prevent="onSubmit">

        <b-form-group
            id="input-group-1"
            label="Email"
            label-for="email">
          <b-form-input
              id="email"
              v-model="credential.email"
              type="email"
              required
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="secondary" class="w-100 text-center">Obnoviť heslo</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      credential: {
        email: '',
      }
    }
  },
  methods: {
    ...mapActions('wAuth', ['forgotPassword']),
    ...mapActions('enums', ['preloadEnums']),
    onSubmit: async function () {
      this.$wToast.clear_loading()

      this.forgotPassword(this.credential).then( async _ => {
        this.$wToast.success('Heslo zresetované!', true)
        this.$router.push('/')
      }).catch(e => this.$wToast.error(e))
    }
  }
}
</script>
<style lang="scss" scoped>
.container-small {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  * {
    text-align: left;
  }
}

.link {
  display: inline-block;
}
</style>
